import React, { Component } from 'react';

class AdblockDetect extends Component {
    constructor(props) {
        super(props);
        this.state = { usingAdblock: false };
    }

    componentDidMount() {
        this.setState({ usingAdblock: this.fakeAdBanner.offsetHeight === 0 });
    }

    componentDidUpdate() {
        this.props.setIsAdblockEnabled(this.state.usingAdblock);
    }

    render() {

        return (
            <div
                ref={r => (this.fakeAdBanner = r)}
                style={{ height: '1px', width: '1px', visiblity: 'none', pointerEvents: 'none' }}
                className="adBanner"
            />
        );
    }
}

export default React.memo(AdblockDetect);