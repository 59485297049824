import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import Launch from '@material-ui/icons/Launch';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';
import AmazonLogo from '../../assets/amazon_logo.png';
import './index.css';
import { getProductIdByURL } from '../../utils/utils';

const TAG = 'sbs0e48-20';

function getRefLink(url) {
    const queryIdx = url.indexOf('?');
    if (queryIdx === -1) {
        return `${url}?tag=${TAG}`;
    } else {
        const tagIdx = url.toLowerCase().indexOf('tag');
        if (tagIdx === -1) {
            return `${url}&tag=${TAG}`;
        } else {
            const regex = /tag=[^&]*/gi;
            return url.replace(regex, `tag=${TAG}`);
        }
    }
}

function getImgSrc(productId) {
    if (typeof productId !== 'string') {
        return;
    }
    // return `//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${productId.toUpperCase()}&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=${TAG}&language=en_US`; // Deprecated
    return `https://images.amazon.com/images/P/${productId.toUpperCase()}.jpg`;
}

const transformLinkUri = (uri) => {
    if (uri && uri.toLowerCase().includes('amazon')) {
        const myUrl = getRefLink(uri);
        return ReactMarkdown.uriTransformer(myUrl);
    }
    return ReactMarkdown.uriTransformer(uri);
};

const onReflinkClick = (productUrl, permalink) => {
    window.gtag && window.gtag('event', 'click', {
        'event_category': 'Product',
        'event_label': permalink,
        'product_url': productUrl,
    });
}

const CommentText = React.memo((props) => {
    const { body } = props;
    const [isOverflow, setIsOverflow] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const ref = useRef(null);
    useEffect(() => setIsOverflow(ref.current.clientHeight > 100), []);

    return (
        <div id="comment-text-container" ref={ref}>
            <div className="comment-text-sub-container">
                <ReactMarkdown transformLinkUri={transformLinkUri} linkTarget="_blank" className={`comment-text ${isCollapsed ? 'collapsed' : 'expanded'}`} source={body} />
            </div>
            {isOverflow &&
                <div role="button" tabIndex="0" className="expand-button" onClick={() => setIsCollapsed(!isCollapsed)}>
                    <span>{isCollapsed ? 'expand' : 'collapse'}</span>
                    {isCollapsed ? <ExpandMore /> : <ExpandLess />}
                </div>
            }
        </div>
    );
});

const AmazonPreviewImage = React.memo(props => {
    const { productUrl, permalink, productId, productImgSrc } = props;
    const [imgBorked, setImgBorked] = useState(false);

    // Check if the image is a single pixel fallback, to set error state
    const handleImageLoad = (e) => {
        const img = e.target;
        if (img.naturalWidth === 1 && img.naturalHeight === 1) {
          setImgBorked(true); // Image is likely a 1x1 fallback
          e.target.style = 'display: none';
        }
      };

    return (
        <a href={productUrl} target="_blank" rel="noopener noreferrer" className={`${imgBorked ? '' : 'comment-img-wrapper-box'} comment-img-wrapper`} onClick={() => onReflinkClick(productUrl, permalink)}>
            <img id="amazon-icon" src={AmazonLogo} alt="Amazon link logo" className={imgBorked ? 'hidden' : ''} />
            <Launch id="external-link-icon" className={imgBorked ? 'hidden' : ''} />
            <div className="product-anchor light-bg-link">
                {productId && !imgBorked ? <img className="product-img" src={productImgSrc} alt="Product link" onLoad={handleImageLoad} onError={e => { e.target.style = 'display: none'; setImgBorked(true) }} /> : 'Link to product'}
            </div>
        </a>
    );
});

const AmazonProductPreview = React.memo(props => {
    const { productId } = props;
    return (
        <div className='comment-img-wrapper comment-img-wrapper-box'>
            <iframe
                title={`Link to product ${productId} on Amazon`}
                style={{ width: '120px', height: '240px', marginTop: '10px', marginBottom: '10px' }}
                marginWidth="0"
                marginHeight="0"
                scrolling="no"
                frameBorder="0"
                loading="lazy"
                src={`//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=${TAG}&marketplace=amazon&region=US&asins=${productId.toUpperCase()}&show_border=false&link_opens_in_new_window=true&price_color=30ad3b&title_color=212121&bg_color=ffffff`}
            >
            </iframe>
        </div>
    );
});

const Comment = React.memo((props) => {
    const { showSubreddit, shouldDisplayProductPreview } = props;
    const { username, score, body, reflink, permalink, subreddit } = props.comment;
    const productId = getProductIdByURL(reflink);
    const productImgSrc = getImgSrc(productId);
    const productUrl = `https://${getRefLink(reflink)}`;

    return (
        <div tabIndex="0" className="comment-wrapper">
            {showSubreddit && <Link className="subreddit-comment light-bg-link" to={`/${subreddit}`}>/r/{subreddit}</Link>}
            {!shouldDisplayProductPreview && <AmazonPreviewImage productId={productId} productImgSrc={productImgSrc} productUrl={productUrl} permalink={permalink} />}
            {productId && shouldDisplayProductPreview && <AmazonProductPreview productId={productId} />}
            <span className={score > 0 ? 'score-positive score' : 'score-negative score'}>{score > 0 ? `+${score}` : score}</span>
            <CommentText body={body} />
            <div className="bottom-comment-card-container">
                <a tabIndex="0" className="permalink light-bg-link" target="_blank" rel="noopener noreferrer" href={'https://reddit.com' + permalink}>permalink</a>
                <div className="username">
                    - <a tabIndex="0" className="light-bg-link" target="_blank" rel="noopener noreferrer" href={`https://reddit.com/u/${username}`}>/u/{username}</a>
                </div>
            </div>
        </div>
    );
});

export default Comment;
