import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import SubPage from './components/SubPage';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/*">
            <SubPage />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
