import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { DropdownButton, MenuItem } from '@trendmicro/react-dropdown';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import debounce from 'lodash/debounce';
import Header from '../Header';
import Comment from '../Comment';
import Spinner from '../Spinner';
import Filter from '../Filter';
import { withRouter } from 'react-router-dom';
import './index.css';
import { sortBy, highestScoreFirst, lowestScoreFirst, recentFirst, oldestFirst, logPageViewEvent, getPathName, getSubNameByPathname } from '../../utils/utils';
import SuggestionBox from '../SuggestionBox';
import AdblockDetect from '../AdblockDetect';

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;

const disallowListTop100 = {'MensRights': true, 'Lolita': true, 'AskMen': true, 'pregnant': true};

class SubPage extends Component {
    prevRoute = '';
    initialComments;

    constructor(props) {
        super(props);
        this.state = { subName: '', comments: [], loading: false, sortOption: highestScoreFirst, isAdblockEnabled: false };
    }

    componentDidMount() {
        this.updateStateByRoute();
    }

    componentDidUpdate() {
        if (this.prevRoute !== getPathName()) {
            this.updateStateByRoute();
        }
    }

    get sorryComponent() {
        return (
            <>
                <span className="error">Sorry... :( I don't have any links for that sub</span>
                <SuggestionBox />
            </>
        );
    }

    get noFilteredResultsComponent() {
        return (
            <span className="error">No results</span>
        );
    }

    generateCommentsJSX(data, subName) {
        // Get tracking state
        const amazonCookiesDisabled = JSON.parse(localStorage.getItem('amazonCookiesDisabled'));
        const shouldDisplayProductPreview = !(this.state.isAdblockEnabled || isFirefox || amazonCookiesDisabled);

        return (subName === 'Most Recent' ? data.filter(comment => !(comment.subreddit in disallowListTop100)) : data).map((comment, idx) => {
            return <Comment key={idx + subName + Math.random()} comment={comment} showSubreddit={subName === 'Most Recent'} shouldDisplayProductPreview={shouldDisplayProductPreview} />;
        });
    }

    async getRestOfComments() {
        const { subName, comments } = this.state;
        if (subName === 'Most Recent') {
            return;
        }

        const response = await fetch(`https://f.shopbysub.com/comments/${subName.toLowerCase()}-rest.json`);
        if (response.status !== 200 && response.status !== 304) {
            return;
        }

        const data = await response.json();
        const restOfComments = this.generateCommentsJSX(data, subName);
        const sortedComments = sortBy([...comments, ...restOfComments], this.state.sortOption);
        this.setState({ comments: sortedComments });
    }

    async updateStateByRoute() {
        // Clear any comments that were saved
        this.initialComments = null;

        const pathname = getPathName();
        logPageViewEvent(pathname); // GA
        this.prevRoute = pathname;

        const subName = pathname === '/' ? 'Most Recent' : getSubNameByPathname(pathname);
        this.setState({ subName, sortOption: subName === 'Most Recent' ? recentFirst : highestScoreFirst, loading: true });
        const response = pathname === '/' ? await fetch(`https://f.shopbysub.com/recent100.json`) : await fetch(`https://f.shopbysub.com/comments/${subName}.json`);
        if (response.status !== 200 && response.status !== 304) {
            this.setState({ loading: false });
            return this.setState({ comments: [] });
        }

        const data = await response.json();
        const comments = this.generateCommentsJSX(data, subName);
        const sortedComments = sortBy(comments, this.state.sortOption);
        const properlyFormattedSubName = subName === 'Most Recent' ? subName : data.length > 1 && data[0].subreddit;

        this.setState({ comments: sortedComments, subName: properlyFormattedSubName || subName, loading: false });
        this.getRestOfComments();
    }

    onDropdownSelect = (sortOption) => {
        const comments = sortBy(this.state.comments, sortOption);
        this.setState({ comments, sortOption });
    }

    setIsAdblockEnabled = (isAdblockEnabled) => {
        this.setState({ isAdblockEnabled });
    }

    onFilter = (val) => {
        if (!this.initialComments) {
            this.initialComments = this.state.comments;
        }
        const comments = val ?
            this.initialComments.filter(comment => comment.props.comment.body.toLowerCase().includes(val.toLowerCase())) :
            this.initialComments;
        this.setState({ comments });
    }

    render() {
        const { subName, comments, loading, sortOption } = this.state;
        const pageTitle = subName === 'Most Recent' ? `ShopBySub.com - Amazon products mentioned on Reddit` : `ShopBySub.com - Shop within the ${subName} subreddit`;
        const descriptionMeta = subName === 'Most Recent' ? `Browse and shop upvoted products on Reddit.` : `Discover products recommended by users within the ${subName} subreddit.`;
        const debouncedOnFilter = debounce(this.onFilter, 150);

        return (
            <>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content={descriptionMeta} />
                </Helmet>
                <Header />
                <div id="page-content">
                    <h1 id="title">
                        {subName === 'Most Recent' ?
                            <>
                                <span>Most Recent</span>
                                <br />
                                <span id="subtitle">New comment ingestion disabled due to Reddit API cost surge, sorry!</span>
                            </> :
                            `/r/${subName}`}
                    </h1>
                    <span id="disclaimer">As an Amazon Associate I earn from qualifying purchases</span>
                    <div id="controls-wrapper">
                        <DropdownButton btnSize="medium" style={{ width: '152px' }} title={sortOption} onSelect={this.onDropdownSelect}>
                            <MenuItem className="dropdown-item" eventKey={highestScoreFirst}>{highestScoreFirst}</MenuItem>
                            <MenuItem className="dropdown-item" eventKey={lowestScoreFirst}>{lowestScoreFirst}</MenuItem>
                            <MenuItem className="dropdown-item" eventKey={recentFirst}>{recentFirst}</MenuItem>
                            <MenuItem className="dropdown-item" eventKey={oldestFirst}>{oldestFirst}</MenuItem>
                        </DropdownButton>
                        <Filter id="filter" onFilter={debouncedOnFilter} />
                    </div>
                    <div id="comment-wrapper">
                        {loading ? <Spinner /> : comments.length > 0 ? comments : this.initialComments && this.initialComments.length > 0 ? this.noFilteredResultsComponent : this.sorryComponent}
                    </div>
                </div>
                <AdblockDetect setIsAdblockEnabled={this.setIsAdblockEnabled} />
            </>
        )
    }
}

export default withRouter(SubPage);
