import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search.js';
import { List } from 'react-virtualized';
import './index.css';

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 50;
    const height = Math.min(250, itemSize * itemCount);

    return (
        <div ref={ref}>
            <div {...other} className="searchlist">
                <List
                    height={height}
                    width={300}
                    rowHeight={itemSize}
                    overscanCount={5}
                    rowCount={itemCount}
                    rowRenderer={props => {
                        return React.cloneElement(children[props.index], {
                            style: props.style
                        });
                    }}
                    role={role}
                />
            </div>
        </div>
    );
});

const Search = React.memo((props) => {
    const { options, onChange } = props;
    return (
        <Autocomplete
            freeSolo
            onChange={onChange}
            options={options}
            ListboxComponent={ListboxComponent}
            renderInput={(params) => (
                <div id="search-sub-wrapper">
                    <TextField {...params} role="listbox" label="search by subreddit" margin="normal" variant="outlined" />
                    <SearchIcon id="search-icon" className={params.inputProps.value.length > 0 ? 'hidden' : ''} />
                </div>
            )}
        />
    );
});

export default Search;