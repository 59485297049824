import { createBrowserHistory } from 'history';

export function isMobile() {
    return document.documentElement.clientWidth < 1200;
}

export function sleep(s) {
    return new Promise((resolve) => setTimeout(resolve, s * 1000));
}

export function getProductIdByURL(url) {
    if (!url) {
        return;
    }

    const dpRegexMatch = url.match(/\/dp\/[a-z0-9A-Z]{10}/);
    if (dpRegexMatch) {
        // remove e.g. /dp/DPRODUC93f => DPRODUC93f
        return dpRegexMatch.toString().substring(4);
    }

    const productRegexMatch = url.toLowerCase().match(/\/product\/[a-z0-9]{10}/);
    if (productRegexMatch) {
        // remove e.g. /product/DPRODUC93f => DPRODUC93f
        return productRegexMatch.toString().substring(9);
    }

    const lazy = url.match(/\/[a-z0-9A-Z]{10}/);
    if (lazy) {
        // remove e.g. /DPRODUC93f => DPRODUC93f
        return lazy.toString().substring(1);
    }
}

export const lowestScoreFirst = 'lowest score first';
export const highestScoreFirst = 'highest score first';
export const recentFirst = 'newest first';
export const oldestFirst = 'oldest first';
export function sortBy(comments, sortOption) {
    let sortedComments;
    switch (sortOption) {
        case highestScoreFirst:
            sortedComments = comments.sort((a, b) => b.props.comment.score - a.props.comment.score);
            break;
        case lowestScoreFirst:
            sortedComments = comments.sort((a, b) => a.props.comment.score - b.props.comment.score);
            break;
        case recentFirst:
            sortedComments = comments.sort((a, b) => b.props.comment.created - a.props.comment.created);
            break;
        case oldestFirst:
            sortedComments = comments.sort((a, b) => a.props.comment.created - b.props.comment.created);
            break;
        default:
            sortedComments = comments;
            break;
    }
    return sortedComments;
}

export function logPageViewEvent(pathname) {
    window.gtag && window.gtag('event', 'page_view', {
        page_title: pathname,
        page_location: window.location,
        page_path: pathname
    });
}

export function getPathName() {
    const pathname = createBrowserHistory().location.pathname
    return pathname.substring(0, 3) === '/r/' ? pathname.substring(2) : pathname;
}

export function getSubNameByPathname(pathname) {
    return pathname.substring(1).toLowerCase().replace('/', '');
}
