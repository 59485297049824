import React from 'react';
import TextField from '@material-ui/core/TextField';
import './index.css';

const Filter = React.memo((props) => {
    const { onFilter } = props;
    return (
        <TextField onChange={event => onFilter(event.target.value)} role="listbox" label="filter" margin="normal" variant="outlined" />
    );
});

export default Filter;