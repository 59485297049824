import React, { useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Header from '../Header';
import './index.css';

const privacyPolicyText = `\tShopBySub.com does not collect personally identifiable information. ShopBySub.com collects general anonymized session analytics via Google Analytics. ShopBySub.com embeds Amazon iframes in order to display pricing information. We have no control over tracking cookies within this iframe.\nIf you would like to disable Amazon's tracking cookies, you may do so using the checkbox below. You will no longer be able to see product pricing information unless you check the checkbox to reenable Amazon cookies. This setting has no effect in Firefox or if you're running adblock software as Amazon tracking cookies are disabled by default.`;

const storeAmazonCookiesSetting = (disabled) => {
    localStorage.setItem('amazonCookiesDisabled', disabled);
}

const PrivacyPolicy = React.memo((props) => {
    const [amazonCookiesDisabled, setAmazonCookiesDisabled] = useState(JSON.parse(localStorage.getItem('amazonCookiesDisabled')));
    return (
        <>
            <Header />
            <div id="pp-container">
                <h1 id="pp-title">Privacy Policy</h1>
                <p id="pp-text">{privacyPolicyText}</p>
                <div>
                    <span>Amazon product previews {amazonCookiesDisabled ? 'disabled' : 'enabled'} and Amazon tracking cookies {amazonCookiesDisabled ? 'disabled' : 'enabled'}</span>
                    <ToggleButton id="cookie-toggle" value="check" selected={amazonCookiesDisabled} onChange={() => { setAmazonCookiesDisabled(!amazonCookiesDisabled); storeAmazonCookiesSetting(!amazonCookiesDisabled) }}>
                        {amazonCookiesDisabled && <CheckBoxOutlineBlankIcon />}
                        {!amazonCookiesDisabled && <CheckBoxIcon />}
                    </ToggleButton>
                </div>
            </div>
        </>
    )
});

export default PrivacyPolicy;