import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Search from '../Search';
import './index.css';
import { isMobile } from '../../utils/utils';
import { throwStatement } from '@babel/types';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { subs: [] };
        this.searchRef = React.createRef();
        this.ppRef = React.createRef();
    }

    async componentDidMount() {
        if (isMobile()) {
            window.addEventListener('scroll', this.onScroll);
        }

        const response = await fetch('https://f.shopbysub.com/sublist.json');
        if (response.status !== 200 && response.status !== 304) {
            return;
        }
        const subs = await response.json();
        this.setState({ subs });
    }

    componentWillUnmount() {
        if (isMobile()) {
            window.removeEventListener('scroll', this.onScroll);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.subs.length === 0 && nextState.subs.length > 0;
    }

    handleSubmit = (event, value) => {
        if (!value) {
            return;
        }

        // Prevents any trailing slash from mucking up the request for comments
        const pathname = this.props.history.location.pathname;
        if (pathname !== '/' && pathname[pathname.length - 1] === '/') {
            this.props.history.location.pathname = pathname.substring(0, pathname.length - 1);
        }

        this.props.history.replace(value);
        event.preventDefault();
    }

    onScroll = () => {
        if (this.searchRef) {
            let offsetTop = this.searchRef.offsetTop;
            if (window.pageYOffset > offsetTop) {
                this.searchRef.className = 'sticky';
                this.ppRef.className = 'extra-padding-top'
            } else {
                this.searchRef.className = '';
                this.ppRef.className = ''
            }
        }
    }

    render() {
        const { subs } = this.state;
        return (
            <div className="header-wrapper">
                <Link id="shop-by-sub-text" className="link" to="/">ShopBySub.com</Link>
                <div id="search-wrapper-wrapper" ref={ref => this.searchRef = ref}>
                    <div id="search" >
                        <Search onChange={this.handleSubmit} options={subs} />
                    </div>
                </div>
                <span id="discover-text">Discover products mentioned on Reddit</span>
                <div id="right-nav-wrapper" ref={ref => this.ppRef = ref}>
                    <Link id="pp-link" className="light-bg-link" to="/privacy-policy">Privacy Policy</Link>
                </div>
            </div>
        )
    }
}

export default withRouter(Header);